import React from 'react'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

const TermsPage = () => {
  return (
    <div className="container min-h-screen px-4 mx-auto">
      <Header
      // title="Buy Life Insurance Online with Inszu - Terms"
      // description="Please read the Terms of Use carefully before you start to use the Platform. By using the Platform or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Platform."
      />
      <section>
        <div className="py-4 md:py-16">
          <h1 className="pt-8 pb-8 text-3xl text-center">Terms of Use</h1>
          <div className="p-8 text-left bg-white rounded inszu-shadow">
            <p className="mb-4 leading-relaxed text-ebony/70">
              These terms of use are entered into by and between You and Inszu LLC (“<strong>Company</strong>”, “
              <strong>we</strong>”, or “<strong>us</strong>”). The following terms and conditions, together with any
              documents they expressly incorporate by reference (collectively, these “<strong>Terms of Use</strong>”),
              govern your access to and use of Inszu.com (“<strong>Website</strong>”) or the Lobis Mobile App (“
              <strong>App</strong>”), including any content, functionality, and services offered on or through Website
              or App (“<strong>Platform</strong>”), whether as a guest or a registered user.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              Please read the Terms of Use carefully before you start to use the Platform.{' '}
              <strong>
                By using the Platform or by clicking to accept or agree to the Terms of Use when this option is made
                available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy
                Policy, incorporated herein by reference.
              </strong>
              If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the
              Platform
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The Platform is offered and available to users who are 18 years of age or older, and reside in the United
              States or any of its territories or possessions. By using the Platform, you represent and warrant that you
              are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility
              requirements. If you do not meet all of these requirements, you must not access or use the Platform.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Changes to the Terms of Use</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We may revise and update these Terms of Use from time to time in our sole discretion. All changes are
              effective immediately when we post them, and apply to all access to and use of the Platform thereafter.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              Your continued use of the Platform following the posting of revised Terms of Use means that you accept and
              agree to the changes. You are expected to check this page from time to time so you are aware of any
              changes, as they are binding on you.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Accessing the Platform and Account Security</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We reserve the right to withdraw or amend the Platform, and any service or material we provide on the
              Platform, in our sole discretion without notice. We will not be liable if for any reason all or any part
              of the Platform is unavailable at any time or for any period. From time to time, we may restrict access to
              some parts of the Platform, or the entire Platform, to users, including registered users
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">You are responsible for:</p>
            <div className="px-8 text-muted">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>Making all arrangements necessary for you to have access to the Platform.</li>
                <li>
                  Ensuring that all persons who access the Platform are aware of these Terms of Use and comply with
                  them.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              To access the Platform or some of the resources it offers, you may be asked to provide certain
              registration details or other information. It is a condition of your use of the Platform that all the
              information you provide is correct, current, and complete. You agree that all information you provide to
              register with the Platform or otherwise, including but not limited to through the use of any interactive
              features on the Platform, is governed by our Privacy Policy, and you consent to all actions we take with
              respect to your information consistent with our Privacy Policy.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              If you choose, or are provided with, a user name, password, or any other piece of information as part of
              our security procedures, you must treat such information as confidential, and you must not disclose it to
              any other person or entity. You also acknowledge that your account is personal to you and agree not to
              provide any other person with access to the Platform or portions of it using your user name, password, or
              other security information. You agree to notify us immediately of any unauthorized access to or use of
              your user name or password or any other breach of security. You also agree to ensure that you exit from
              your account at the end of each session. You should use particular caution when accessing your account
              from a public or shared computer so that others are not able to view or record your password or other
              personal information.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We have the right to disable any user name, password, or other identifier, whether chosen by you or
              provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you
              have violated any provision of these Terms of Use.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Intellectual Property Rights</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The Platform and its entire contents, features, and functionality (including but not limited to all
              information, software, text, displays, images, video, and audio, and the design, selection, and
              arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are
              protected by United States and international copyright, trademark, patent, trade secret, and other
              intellectual property or proprietary rights laws.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              These Terms of Use permit you to use the Platform for your personal use only. You must not reproduce,
              distribute, modify, create derivative works of, publicly display, publicly perform, republish, download,
              store, or transmit any of the material on our Platform, except as follows:
            </p>
            <div className="px-8 text-muted">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  Your computer or other device may temporarily store copies of such materials in RAM incidental to your
                  accessing and viewing those materials.
                </li>
                <li>
                  You may store files that are automatically cached by your Web browser for display enhancement
                  purposes.
                </li>
                <li>
                  You may print or download one copy of a reasonable number of pages of the Website for your own
                  personal, non-commercial use and not for further reproduction, publication, or distribution.
                </li>
                <li>
                  If we provide desktop, mobile, or other applications for download, you may download a single copy to
                  your computer or mobile device solely for your own personal, non-commercial use, provided you agree to
                  be bound by our end user license agreement for such applications.
                </li>
                <li>
                  If we provide social media features with certain content, you may take such actions as are enabled by
                  such features.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">You must not:</p>
            <div className="px-8 text-muted">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>Modify copies of any materials from the Platform.</li>
                <li>
                  Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials
                  from this site.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              You must not access or use for any commercial purposes any part of the Platform or any services or
              materials available through the Platform.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              If you print, copy, modify, download, or otherwise use or provide any other person with access to any part
              of the Platform in breach of the Terms of Use, your right to use the Platform will stop immediately and
              you must, at our option, return or destroy any copies of the materials you have made. No right, title, or
              interest in or to the Platform or any content on the Platform is transferred to you, and all rights not
              expressly granted are reserved by the Company. Any use of the Platform not expressly permitted by these
              Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Trademarks</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The Company name, the terms Inszu™, the Inszu Design, and all related names, logos, product and service
              names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use
              such marks without the prior written permission of the Company. All other names, logos, product and
              service names, designs, and slogans on the Platform are the trademarks of their respective owners.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Prohibited Uses</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              You may use the Platform only for lawful purposes and in accordance with these Terms of Use. You agree not
              to use the Platform:
            </p>
            <div className="px-8 text-muted">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  In any way that violates any applicable federal, state, local, or international law or regulation
                  (including, without limitation, any laws regarding the export of data or software to and from the US
                  or other countries).
                </li>
                <li>
                  For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing
                  them to inappropriate content, asking for personally identifiable information, or otherwise.
                </li>
                <li>
                  To send, knowingly receive, upload, download, use, or re-use any material that does not comply with
                  the Content Standards set out in these Terms of Use.
                </li>
                <li>
                  To transmit, or procure the sending of, any advertising or promotional material without our prior
                  written consent, including any “junk mail”, “chain letter”, “spam”, or any other similar solicitation.
                </li>
                <li>
                  To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other
                  person or entity.
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Platform,
                  or which, as determined by us, may harm the Company or users of the Platform or expose them to
                  liability.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">Additionally, you agree not to:</p>
            <div className="px-8 text-muted">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  Use the Platform in any manner that could disable, overburden, damage, or impair the site or interfere
                  with any other party’s use of the Platform, including their ability to engage in real time activities
                  through the Platform.
                </li>
                <li>
                  Use any robot, spider, or other automatic device, process, or means to access the Platform for any
                  purpose, including monitoring or copying any of the material on the Platform.
                </li>
                <li>
                  Use any manual process to monitor or copy any of the material on the Platform or for any other
                  unauthorized purpose without our prior written consent.
                </li>
                <li>Use any device, software, or routine that interferes with the proper working of the Platform.</li>
                <li>
                  Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or
                  technologically harmful.
                </li>
                <li>
                  Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Platform,
                  the server on which the Platform is stored, or any server, computer, or database connected to the
                  Platform.
                </li>
                <li>Attack the Platform via a denial-of-service attack or a distributed denial-of-service attack.</li>
                <li>Otherwise attempt to interfere with the proper working of the Platform.</li>
              </ul>
            </div>
            <h3 className="pt-12 pb-4 text-3xl">Monitoring and Enforcement; Termination</h3>
            <p className="pb-4 leading-relaxed text-ebony/70">We have the right to:</p>
            <div className="px-8 text-muted">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  Take appropriate legal action, including without limitation, referral to law enforcement, for any
                  illegal or unauthorized use of the Platform.
                </li>
                <li>
                  Terminate or suspend your access to all or part of the Platform for any or no reason, including
                  without limitation, any violation of these Terms of Use.
                </li>
              </ul>
            </div>
            <p className="py-4 leading-relaxed text-ebony/70">
              Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities
              or court order requesting or directing us to disclose the identity or other information of anyone using
              the Platform. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS
              FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A
              CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Reliance on Information Posted</h3>
            <p className="pb-4 leading-relaxed text-ebony/70">
              The information presented on or through the Platform is made available solely for general information
              purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance
              you place on such information is strictly at your own risk. We disclaim all liability and responsibility
              arising from any reliance placed on such materials by you or any other visitor to the Platform, or by
              anyone who may be informed of any of its contents.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              This Platform may include content provided by third parties, including materials provided by other users,
              and third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or
              opinions expressed in these materials, and all articles and responses to questions and other content,
              other than the content provided by the Company, are solely the opinions and the responsibility of the
              person or entity providing those materials. These materials do not necessarily reflect the opinion of the
              Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any
              materials provided by any third parties.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Changes to the Platform</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We may update the content on the Platform from time to time, but its content is not necessarily complete
              or up-to-date. Any of the material on the Platform may be out of date at any given time, and we are under
              no obligation to update such material.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Information About You and Your Visits to the Platform</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              All information we collect on this Platform is subject to our Privacy Policy. By using the Platform, you
              consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Linking to the Website and Social Media Features</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our
              reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form
              of association, approval, or endorsement on our part without our express written consent
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The Website may provide certain social media features that enable you to:
            </p>
            <div className="px-8 text-muted">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>Link from your own or certain third-party websites to certain content on the Website.</li>
                <li>
                  Send emails or other communications with certain content, or links to certain content, on the Website.
                </li>
                <li>
                  Cause limited portions of content on the Website to be displayed or appear to be displayed on your own
                  or certain third-party websites.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              You may use these features solely as they are provided by us, and solely with respect to the content they
              are displayed with and otherwise in accordance with any additional terms and conditions we provide with
              respect to such features. Subject to the foregoing, you must not:
            </p>
            <div className="px-8 text-muted">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>Establish a link from any website that is not owned by you.</li>
                <li>
                  Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site,
                  for example, framing, deep linking, or in-line linking.
                </li>
                <li>Link to any part of the Website other than the homepage.</li>
                <li>
                  Otherwise take any action with respect to the materials on the Website that is inconsistent with any
                  other provision of these Terms of Use.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              The website from which you are linking, or on which you make certain content accessible, must comply in
              all respects with the Terms of Use.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We
              reserve the right to withdraw linking permission without notice.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We may disable all or any social media features and any links at any time without notice in our
              discretion.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Links from the Website</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              If the Website contains links to other sites and resources provided by third parties, these links are
              provided for your convenience only. This includes links contained in advertisements, including banner
              advertisements and sponsored links. We have no control over the contents of those sites or resources, and
              accept no responsibility for them or for any loss or damage that may arise from your use of them. If you
              decide to access any of the third-party websites linked to this Website, you do so entirely at your own
              risk and subject to the terms and conditions of use for such websites.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Geographic Restrictions</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The owners of the Platform are based in the state of Arizona in the United States and in the state of
              western australia in Australia. We provide the Platform for use only by persons located in the United
              States. We make no claims that the Platform or any of its content is accessible or appropriate outside of
              the United States. Access to the Platform may not be legal by certain persons or in certain countries. If
              you access the Platform from outside the United States, you do so on your own initiative and are
              responsible for compliance with local laws.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Disclaimer of Warranties</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              You understand that we cannot and do not guarantee or warrant that files available for downloading from
              the internet or the Platform will be free of viruses or other destructive code. You are responsible for
              implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus
              protection and accuracy of data input and output, and for maintaining a means external to our site for any
              reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS
              OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL
              MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL
              DUE TO YOUR USE OF THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM OR TO YOUR
              DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY PLATFORM LINKED TO IT.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              YOUR USE OF THE PLATFORM, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM IS AT YOUR
              OWN RISK. THE PLATFORM, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM ARE PROVIDED
              ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
              NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH
              RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE PLATFORM.
              WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR
              WARRANTS THAT THE PLATFORM, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL BE
              ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE
              SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE PLATFORM OR
              ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
              MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Limitation on Liability</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS,
              SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
              LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE PLATFORM, ANY
              WEBSITES LINKED TO IT, ANY CONTENT ON THE PLATFORM, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
              CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
              EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF
              USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
              CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Indemnification</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service
              providers, and its and their respective officers, directors, employees, contractors, agents, licensors,
              suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards,
              losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to (i)
              your violation of these Terms of Use or your use of the Platform, other than as expressly authorized in
              these Terms of Use, or (ii) your use of any information obtained from the Platform.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Governing Law and Jurisdiction</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              All matters relating to the Platform and these Terms of Use and any dispute or claim arising therefrom or
              related thereto (in each case, including non-contractual disputes or claims), shall be governed by and
              construed in accordance with the internal laws of the State of Arizona without giving effect to any choice
              or conflict of law provision or rule (whether of the State of Arizona or any other jurisdiction).
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Platform
              shall be instituted exclusively in the federal courts of the United States or the courts of the State of
              Arizona in each case located in the City of Phoenix and County of Maricopa, although we retain the right
              to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of
              residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction
              over you by such courts and to venue in such courts.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Limitation on Time to File Claims</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE PLATFORM
              MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION
              OR CLAIM IS PERMANENTLY BARRED.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Waiver and Severability</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further
              or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure
              of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of
              such right or provision.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to
              be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the
              minimum extent such that the remaining provisions of the Terms of Use will continue in full force and
              effect.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Entire Agreement</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Inszu LLC
              regarding the Platform and supersede all prior and contemporaneous understandings, agreements,
              representations, and warranties, both written and oral, regarding the Platform.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Your Comments and Concerns</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The Platform is operated by Inszu LLC, 2727 E. Camelback Road, Ste. 304, Phoenix, Arizona, United States,
              85016.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              All feedback, comments, requests for technical support, and other communications relating to the Platform
              should be directed to: info@inszu.com.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default TermsPage
